/* global subheaderSectionComponent */
class CustomSubheaderSectionComponent extends subheaderSectionComponent {

    openSubMenu(cat) {
        if (this.getCatInfo(cat.fields.CatName).length === 0 || (this.$route.name !== "CategorySEO" && this.$route.name !== "ItemViewSEO")) {
            let storeCategory = this.$store.getters.getCategoryDataByCode(cat.fields.CatCode);
            if (storeCategory) {
                window.scrollTo(0, 0);
                let catRoutePath = (`${storeCategory.code}__${storeCategory.name}`);
                let globalCategory = this.$store.getters.getGlobalCategories;
                let paramCategory = this.$route.params.category ? this.$route.params.category.split("__")[0] : "";
                if(globalCategory.length > 0 && this.$route.params.category && (globalCategory.indexOf(storeCategory.code) == -1 && globalCategory.indexOf(paramCategory) != -1) && catRoutePath !== this.$route.params.category) {
                    //this.$store.dispatch('updateCategory', storeCategory);
                    //this.emitEvent("category-select");
                    this.$router.push({
                        name: 'CategorySEO',
                        params: {category: this.$route.params.category},
                        query: {category: catRoutePath}
                    });
                }
                else{
                    if(globalCategory.length > 0 && globalCategory.indexOf(this.$store.state.cat_selected) !== -1) {
                        //this.$store.dispatch('updateCategory', storeCategory);
                        //this.emitEvent("category-select");
                        this.$router.push({
                            name: 'CategorySEO',
                            params: {category: this.$route.params.category},
                            query: {category: catRoutePath}
                        });
                    }
                    else {
                        //this.$store.dispatch('updateCategory', storeCategory);
                        //this.emitEvent("category-select");
                        this.$router.push({
                            name: 'CategorySEO',
                            params: {category: catRoutePath},
                            query: {category: catRoutePath}
                        });
                    }
                }
            }
            if (this.isMobile) {
                this.emitEvent('closeSandwichMenu');
            }
        } else {
            if (JSON.stringify(cat) == JSON.stringify(this.selectedCategory)) {
                this.closeSubMenu();
            } else {
                this.selectedCategory = cat;
            }
        }
    }

    getTemplate() {
        return `<nav class="navbar-expand-lg category-nav" role="navigation">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mr-auto categories" v-click-outside="closeSubMenu">
                    <template v-for="cat in homeCategory">
                        <template v-if="getCatInfo(cat.fields.CatName).length>0">
                            <li :class="[{'show': (selectedCategory == cat),'multi-link':true },subMenuCSS]"  @click="openSubMenu(cat)">
                                <span class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                            {{capitalize(cat.fields.CatName)}}
                                </span>
                                <ul :id="'navbarDropdown'+cat.fields.CatCode" :class="[{show:(selectedCategory == cat)},subMenuCSS2]"  aria-labelledby="false" >
                                  <div class="row">
                                    <div v-if="$route.name == 'CategorySEO' || $route.name == 'ItemViewSEO'" class="col-12 category-title">
                                        <categoryMenuComponent  :key="cat.fields.Code" :category="$store.getters.getCategoryDataByCode(cat.fields.CatCode)" ></categoryMenuComponent>
                                    </div>
                                    <div class="col category-menu">
                                      <categoryMenuComponent v-for="(subcat,keycat) in getCatInfo(cat.fields.CatName)"
                                                     :key="keycat"
                                                     :category="subcat"
                                      ></categoryMenuComponent>
                                      <div class="category-image" v-if="cat.fields.CatPhotoUri">
                                        <div class="image" v-bind:style="{backgroundImage: 'url(' + cat.fields.CatPhotoUri + ')'}"></div>
                                      </div>
                                    </div>
                                  </div>
                                </ul>
                            </li>
                        </template>
                        <template v-else>
                            <li :class="[{'show': (selectedCategory == cat), 'single-link':true },subMenuCSS]" v-if="!cat.fields.isShowShopMenu" @click="openSubMenu(cat)">
                                <span class="nav-link" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                       {{capitalize(cat.fields.CatName)}}
                                </span>
                            </li>
                        </template>
                    </template>
                  </ul>
                </div>
              </nav>`;
    }
}

CustomSubheaderSectionComponent.registerComponent();